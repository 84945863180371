import styles from "./Footer.module.css";
import { Link } from "@ui-kit/components/link/Link";
import { Button } from "@ui-kit/components/buttons/Button";
import cx from "classnames";
import { useTranslation } from "@core/i18n/useTranslation";
import { footerNavigation, routes } from "@core/routes/routes";
import { ContactModal } from "@core/common/components/contact-Modal/ContactModal";
import { useState } from "react";
import { ContactForm } from "./ContactForm";
import { ReactComponent as LinkedInIcon } from "@ui-kit/components/icons/svg/linkedin-logo.svg";
import { ReactComponent as YouTubeIcon } from "@ui-kit/components/icons/svg/youtube-logo.svg";

export const Footer = () => {
  const { t } = useTranslation("common");
  const [showContactModal, setShowContactModal] = useState(false);

  return (
    <footer
      className={cx(
        "bg-white flex xl:block flex-col xl:flex-row pl-4 xl:px-20 pt-8 xl:pt-20 pb-8",
        "text-grey-400 bodySmallText font-light"
      )}
    >
      <div
        className={cx(
          styles.content,
          "flex flex-col xl:flex-row justify-between gap-3 xl:gap-0 min-h-0 xl:min-h-64"
        )}
      >
        <div className={cx(styles.paddingAfterDiv)}>
          <div className={cx(styles.heading, "heading3")}>{t("footer.header")}</div>
          <div>{t("footer.address")}</div>
          <div>
            <a className={"hover:underline"} href={`mailto:${t("footer.email")}`}>
              {t("footer.email")}
            </a>
          </div>
        </div>
        <div className={cx(styles.paddingAfterDiv)}>
          <div className={cx(styles.heading, "heading3")}>{t("footer.sitePlan")}</div>
          {footerNavigation.sitePlan.map((routeKey) => {
            const route = routes[routeKey];
            return (
              <div key={routeKey}>
                <Link href={route.pathname} className={"hover:underline"}>
                  {t(route.translationKey)}
                </Link>
              </div>
            );
          })}
        </div>
        <div>
          <div className={cx(styles.paddingAfterDiv, "pb-3 xl:pb-5")}>
            <div className={cx(styles.heading, "heading3")}>{t("footer.partnerships")}</div>
            {footerNavigation.partnerships.map((routeKey) => {
              const route = routes[routeKey];
              return (
                <div key={routeKey}>
                  <Link href={route.pathname} className={"hover:underline"}>
                    {t(route.translationKey)}
                  </Link>
                </div>
              );
            })}
          </div>
          <div className={cx(styles.paddingAfterDiv)}>
            <div className={cx(styles.heading, "heading3")}>{t("footer.socialMedia")}</div>
            <div>
              <a
                href={"https://linkedin.com/company/altiplano-open-research"}
                target={"_blank"}
                rel="noopener noreferrer"
                className={"hover:underline flex items-center gap-1"}
              >
                <LinkedInIcon className={"icon"} />
                {t("footer.linkedin")}
              </a>
            </div>
            <div>
              <a
                href={"https://www.youtube.com/channel/UCQvEsJ843tBDbvxYWEKwelg"}
                target={"_blank"}
                rel="noopener noreferrer"
                className={"hover:underline flex items-center gap-1"}
              >
                <YouTubeIcon className={"icon"} />
                {t("footer.youtube")}
              </a>
            </div>
          </div>
        </div>
        <div className={"xl:pr-20 pb-6 xl:pb-0"}>
          <div>
            <Button
              textColor={"white"}
              bgColor={"primary-400"}
              onClick={() => setShowContactModal(true)}
            >
              {t("footer.contactUs")}
            </Button>
          </div>
        </div>
      </div>
      <div className={"flex flex-col xl:flex-row justify-between smallText font-light"}>
        <span className={"order-last xl:order-none"}>
          {t("footer.copyright", { year: new Date().getFullYear() })}
        </span>
        <div className={"underline pb-4 xl:pb-0 flex gap-4"}>
          <Link href={routes.termsOfUse.pathname}>{t("footer.termsOfUse")}</Link>
          <Link href={routes.legalNotice.pathname}>{t("footer.legalNotice")}</Link>
          <Link href={routes.privacyPolicy.pathname}>{t("footer.privacyPolicy")}</Link>
        </div>
      </div>
      <ContactModal show={showContactModal} onClose={() => setShowContactModal(false)}>
        <ContactForm setShowContactModal={setShowContactModal} />
      </ContactModal>
    </footer>
  );
};
