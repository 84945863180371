import type { StrapiUser, StrapiUserAnalyst } from "strapi-sdk-js";
import { UserRoleEnum } from "@core/api/authentication/types";

export function isAnalyst(user: StrapiUser): user is StrapiUserAnalyst {
  return [
    UserRoleEnum.PRE_REGISTERED_ANALYST,
    UserRoleEnum.PARTIALLY_REGISTERED_ANALYST,
    UserRoleEnum.ANALYST,
  ].includes(user.role.type);
}
