import { Trans } from "react-i18next";
import { toast, TypeOptions } from "react-toastify";

export const renderHTML = (child: string) => {
  return (
    <div className="break-word">
      <Trans> {child} </Trans>
    </div>
  );
};

export function notify(message: string, type: TypeOptions, html = false) {
  if (html) return toast(renderHTML(message), { type: type });
  return toast(message, { type: type });
}
