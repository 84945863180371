import { ReactComponent as LogoIcon } from "@ui-kit/components/icons/svg/Logo.svg";
import cx from "classnames";
import type { HeaderProps } from "@core/common/layouts/RootLayout/Header/Header";
import { Link } from "@ui-kit/components/link/Link";
import headerStyles from "./Header.module.css";
import styles from "./HeaderDesktop.module.css";
import { headerNavigation, routes } from "@core/routes/routes";
import { useTranslation } from "@core/i18n/useTranslation";

export const HeaderDesktop = ({
  className,
  userPart,
  authPart,
  activeRoute,
  authLoading,
}: Omit<HeaderProps, "isOpen" | "setOpen">) => {
  const { t } = useTranslation("common");

  return (
    <nav
      className={cx(
        className,
        styles.nav,
        "w-full h-20 xl:px-3 2xl:px-20 text-grey-400 bg-white border-b border-grey-200 flex justify-between items-center"
      )}
    >
      <div
        className={cx(
          userPart != null ? styles.navItemsWithUserInfo : styles.navItems,
          "flex xl:gap-2.5 2xl:gap-4 items-center"
        )}
      >
        <Link href={routes.home.pathname} aria-label={t("common:ariaLabels.home")}>
          <LogoIcon className={"w-14 h-14 text-primary-400"} />
        </Link>
        {headerNavigation.map((routeKey) => {
          const route = routes[routeKey];
          return (
            <div className={"relative group"} key={routeKey}>
              <>
                <Link
                  href={route.pathname}
                  className={cx({ [headerStyles.active]: activeRoute === route.pathname }, "py-1")}
                >
                  {t(route.translationKey)}
                </Link>
                <div
                  className={cx(
                    "group-hover:bg-primary-400 absolute -bottom-2 rounded-full left-0 right-0",
                    { ["bg-primary-400"]: activeRoute === route.pathname },
                    headerStyles.activeBar
                  )}
                />
              </>
            </div>
          );
        })}
      </div>
      <div className={"max-w-md h-full flex flex-grow justify-end items-center"}>
        {!authLoading && (
          <>
            {userPart}
            {authPart}
          </>
        )}
      </div>
    </nav>
  );
};
