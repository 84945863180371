import React, { useEffect, useRef, ReactNode } from "react";
import cx from "classnames";
import styles from "./Modal.module.css";
import { useClickOutside } from "@core/common/hooks/useClickOutside";
import { toggleBodyScroll } from "@core/common/utils/bodyScroll";

const ModalSize = ["sm", "md", "xl", "full"] as const;

type ModalProps = {
  show: boolean;
  size?: typeof ModalSize[number];
  onClose: () => void;
  children: ReactNode;
  className?: string;
};

export function Modal({ children, className, show, size = "md", onClose }: ModalProps) {
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => {
    if (size === "full") {
      return;
    }
    onClose();
  });

  useEffect(() => {
    toggleBodyScroll(show);
  }, [show]);

  useEffect(() => {
    return () => {
      toggleBodyScroll(false);
    };
  }, []);

  if (!show) {
    return null;
  }
  return (
    <div
      className={cx("fixed inset-0 z-40 overflow-hidden flex", {
        ["z-50"]: size !== "full",
      })}
    >
      {size !== "full" && <div className="absolute inset-0 bg-grey-400 opacity-70" />}
      <div
        ref={modalRef}
        className={cx(className, "relative bg-white m-auto flex flex-col", styles.modal, {
          ["rounded-lg"]: size !== "full",
          [styles.modalSm]: size === "sm",
          [styles.modalMd]: size === "md",
          [styles.modalXl]: size === "xl",
          [styles.fullScreen]: size === "full",
        })}
      >
        {children}
      </div>
    </div>
  );
}
