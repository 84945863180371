import type { Media } from "@core/api/common/types";
import type { Institution } from "../institutions/model/Institution";
import type { Intrest } from "@core/api/intrests/model/Intrest";
import type { Company } from "@core/api/companies/model/Company";

export enum UserRoleEnum {
  PRE_REGISTERED_ANALYST = "preregisteredanalyst",
  PARTIALLY_REGISTERED_ANALYST = "partiallyregisteredanalyst",
  ANALYST = "analyst",
  INVESTOR = "investor",
}

export type MeetingPreferences = {
  zoom: boolean;
  googleMeet: boolean;
  microsoftTeams: boolean;
  whatsApp: boolean;
  calls: boolean;
};

declare module "strapi-sdk-js" {
  export interface StrapiRegistrationData {
    firstname: string;
    lastname: string;
    email: string;
    phoneNumber: string;
    password: string;
    role: typeof UserRoleEnum.ANALYST | typeof UserRoleEnum.INVESTOR;
  }

  interface StrapiUserCommon {
    biography: string | null;
    confirmed: boolean;
    created_at: string;
    company_coverage: Company[];
    username: string;
    email: string;
    experienceYears: number | null;
    firstname: string;
    id: number;
    intrests: Intrest[];
    isActive: boolean | null;
    jobTitle: string | null;
    lastname: string;
    minimalHourlyRate: number | null;
    profilPictureUrl: Media | null;
    phoneNumber: string;
  }

  export interface StrapiUserAnalyst extends StrapiUserCommon {
    role: {
      type:
        | UserRoleEnum.PRE_REGISTERED_ANALYST
        | UserRoleEnum.PARTIALLY_REGISTERED_ANALYST
        | UserRoleEnum.ANALYST;
    };
  }

  export interface StrapiUserInvestor extends StrapiUserCommon {
    linked_company: Institution;
    meeting_preference: MeetingPreferences | null;
    role: {
      type: UserRoleEnum.INVESTOR;
    };
  }

  export type StrapiUser = StrapiUserAnalyst | StrapiUserInvestor;
}

export interface StrapiError {
  status: string;
  message: { id: string };
  original: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStrapiError(error: any): error is StrapiError {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return typeof error?.message?.id === "string";
}
