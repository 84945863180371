import type { FieldError } from "react-hook-form";
import type { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import cx from "classnames";
import styles from "./TextField.module.css";
import type { ComponentPropsWithoutRef } from "react";
import { useTranslation } from "@core/i18n/useTranslation";

export type TextFieldProps = {
  id: string;
  label: string;
  helper?: string;
  errorText?: string;
  plainText?: boolean;
  error?: FieldError;
  register?: UseFormRegisterReturn;
  inputClassName?: string;
} & ComponentPropsWithoutRef<"input">;

export const TextField = ({
  id,
  label,
  helper,
  errorText,
  error,
  value,
  register,
  plainText,
  className,
  inputClassName,
  placeholder,
  ...props
}: TextFieldProps) => {
  const { t } = useTranslation("common");
  const errorMessage = errorText !== undefined ? errorText : t("form.error.required");

  return (
    <div className={cx(className, "flex flex-col gap-y-4 text-grey-300 bodySmallText")}>
      <label htmlFor={id} className={"formLabel font-normal"}>
        {label}
      </label>
      {plainText !== undefined ? (
        <p>{value}</p>
      ) : (
        <input
          className={cx(
            styles.input,
            "form-input focus:border-grey-300 focus:ring-grey-300 rounded border-grey-200 bodySmallText py-4",
            inputClassName
          )}
          id={id}
          placeholder={placeholder !== undefined ? placeholder : label}
          value={value}
          {...register}
          {...props}
        />
      )}
      {(Boolean(error) || Boolean(helper)) && (
        <span className={cx({ "text-error-main": Boolean(error) })}>
          {Boolean(error) && `${errorMessage}${helper !== undefined ? ": " : ""}`}
          {Boolean(helper) && helper}
        </span>
      )}
    </div>
  );
};
