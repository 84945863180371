import { Button } from "@ui-kit/components/buttons/Button";
import { ReactComponent as CloseIcon } from "@ui-kit/components/icons/svg/close.svg";
import { Modal } from "@ui-kit/components/modals/Modal";
import styles from "./ContactModal.module.css";
import React, { ReactNode } from "react";
import cx from "classnames";

type ContactModalProps = {
  show: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  disabledSubmit?: boolean;
};

export const ContactModal = ({ show, title, children, onClose }: ContactModalProps) => (
  <Modal show={show} onClose={onClose} size={"xl"} className="overflow-auto max-h-screen">
    <header className={cx(styles.header, { [styles.withTitle]: title !== undefined })}>
      <span />
      <h4 className="heading4 flex-1 text-center text-grey-400">{title}</h4>
      <Button
        bgColor="white"
        textColor="grey-400"
        onClick={onClose}
        small={true}
        className="ml-auto"
        icon={<CloseIcon className="icon text-base" />}
        type="button"
      />
    </header>
    {children}
  </Modal>
);
