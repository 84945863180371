import type { FieldError } from "react-hook-form";
import type { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import cx from "classnames";
import styles from "./TextArea.module.css";
import type { ComponentPropsWithoutRef } from "react";

export type TextAreaProps = {
  id: string;
  label: string;
  helper?: string;
  errorText: string;
  error?: FieldError;
  register: UseFormRegisterReturn;
  inputClassName?: string;
} & ComponentPropsWithoutRef<"textarea">;

export const TextArea = ({
  id,
  label,
  helper,
  errorText,
  error,
  register,
  className,
  inputClassName,
  ...props
}: TextAreaProps) => {
  return (
    <div className={cx(className, "flex flex-col gap-y-4 text-grey-300 bodySmallText")}>
      <label htmlFor={id} className={"formLabel font-normal"}>
        {label}
      </label>
      <textarea
        className={cx(
          styles.input,
          "form-input focus:border-grey-300 focus:ring-grey-300 rounded border-grey-200 bodySmallText py-4 resize-none",
          inputClassName
        )}
        id={id}
        placeholder={label}
        {...register}
        {...props}
      />
      {(Boolean(error) || Boolean(helper)) && (
        <span className={cx({ "text-error-main": Boolean(error) })}>
          {Boolean(error) && `${errorText}${helper !== undefined ? ": " : ""}`}
          {Boolean(helper) && helper}
        </span>
      )}
    </div>
  );
};
