import {
  string,
  pattern,
  coerce,
  optional,
  define,
  object,
  integer,
  refine,
  boolean,
} from "superstruct";

/**
 * RFC 5322 compliant
 * @see http://emailregex.com/
 */
export const Email = pattern(
  string(),
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

/**
 * @see https://regexr.com/3c53v
 */
export const Phone = pattern(
  string(),
  // eslint-disable-next-line no-useless-escape
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
);

export const OptionalString = coerce(optional(string()), string(), (value) =>
  value.trim().length === 0 ? undefined : value
);

/**
 * @see https://ihateregex.io/expr/password/
 */
export const Password = pattern(
  string(),
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
);

/**
 * @see https://ihateregex.io/expr/url/
 */
export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;

/**
 * @see https://github.com/ianstormtaylor/superstruct/issues/820
 */
export const checkPasswordsMatch = (field: string) =>
  define<string>("checkPasswordsMatch", (value, { branch }): boolean | string => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return branch[0]?.[field] === value ? true : "Error message: passwords must match";
  });

export const PartialMediaSchema = object({
  id: optional(integer()),
  url: optional(string()),
  name: optional(string()),
});

export const MediaSchema = object({
  id: integer(),
  url: string(),
  name: string(),
});

export const PositiveOrZeroInteger = () =>
  refine(integer(), "positiveOrZero", (value) => value >= 0);

const MeetingPreferencesSchema = object({
  zoom: boolean(),
  googleMeet: boolean(),
  microsoftTeams: boolean(),
  whatsApp: boolean(),
  calls: boolean(),
});

export const MeetingPreferencesValidation = refine(
  MeetingPreferencesSchema,
  "atLeastOneTrue",
  (value) => {
    const { zoom, googleMeet, microsoftTeams, whatsApp, calls } = value;
    return zoom || googleMeet || microsoftTeams || whatsApp || calls;
  }
);

export const dateAfterOtherDate = (field: string) =>
  define<string>("dateAfterOtherDate", (value, { branch }): true | string => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const fieldValue: unknown = branch[0]?.[field];
    if (typeof value !== "string" || typeof fieldValue !== "string") {
      return `Error message: invalid types for value: '${typeof value}' or field: '${typeof fieldValue}'`;
    }
    return new Date(value) > new Date(fieldValue)
      ? true
      : `Error message: date '${value}' must be later than '${fieldValue}' value`;
  });

export const dateAfterNow = () =>
  define<string>("dateAfterNow", (value): true | string => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (typeof value !== "string") {
      return `Error message: invalid type for value: '${typeof value}''`;
    }
    return new Date(value) > new Date()
      ? true
      : `Error message: date '${value}' must be later than now`;
  });
