const MAX_LENGTH = 20;

export const getFullName = (
  firstname?: string | null,
  lastname?: string | null,
  truncate = true
) => {
  const firstName = firstname ?? "";
  const lastName = lastname ?? "";
  let fullName = `${firstName} ${lastName}`;

  if (!truncate) {
    return fullName;
  }
  if (fullName.length > MAX_LENGTH) {
    const firstNameInitial = getInitials(firstName);
    fullName = `${firstNameInitial}. ${lastName}`;
  }
  return fullName;
};

const getInitials = (name: string) => {
  const initials = name.split(/-| /);
  let initial = "";

  initials.map((i) => {
    initial = initial + i.charAt(0);
  });
  return initial.toUpperCase();
};
