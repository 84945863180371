import { useState, useEffect, useMemo } from "react";
import { useWindowDimensions } from "./useWindowDimensions";
import { theme } from "@core/theme";

export function useResponsive() {
  const { width } = useWindowDimensions();
  const screenXl = useMemo(() => theme.screens.xl, []);
  const desktopDefaultSize = useMemo(
    () => parseInt(screenXl.substring(0, screenXl.length - 2)),
    [screenXl]
  );
  const isMobileDefault = width < desktopDefaultSize;
  const [isMobileSize, setIsMobileSize] = useState(isMobileDefault);

  useEffect(() => {
    setIsMobileSize(width < desktopDefaultSize);
  }, [width, screenXl, desktopDefaultSize]);

  return { isMobileSize };
}
