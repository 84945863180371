import type { ReactNode } from "react";
import cx from "classnames";
import styles from "./RootLayout.module.css";
import { Footer } from "./Footer/Footer";
import { Header } from "@core/common/layouts/RootLayout/Header/Header";
import { useEffect, useState } from "react";
import { toggleBodyScroll } from "@core/common/utils/bodyScroll";

type RootLayoutProps = {
  className?: string;
  bgClass?: string;
  children: ReactNode;
};
export const RootLayout = ({ className, bgClass = "bg-grey-100", children }: RootLayoutProps) => {
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);

  // To prevent the body from scrolling in the background when the mobile navbar is open
  useEffect(() => {
    toggleBodyScroll(isMobileNavbarOpen);
  }, [isMobileNavbarOpen]);

  return (
    <div className={cx(styles.container, className, bgClass)}>
      <Header isOpen={isMobileNavbarOpen} setOpen={setMobileNavbarOpen} />
      {children}
      <Footer />
    </div>
  );
};
