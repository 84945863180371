import { strapi } from "@core/common/utils/strapi";
import type { ContactFormSchema } from "@core/common/layouts/RootLayout/Footer/ContactFormSchema";

type contactEndpointResponse = {
  message: string;
};

export function contactAltiplano(body: ContactFormSchema): Promise<contactEndpointResponse> {
  return strapi.create(`contact-us`, body);
}
