import Strapi from "strapi-sdk-js";

const url = process.env.NEXT_PUBLIC_BACKEND_API_URL ?? "http://localhost";
const port = process.env.NEXT_PUBLIC_BACKEND_API_PORT ?? "1337";

export const API_URL = `${url}:${port}`;
export const STRAPI_COOKIE = "strapi_jwt";
export const AUTH_EXPIRATION_IN_DAYS = 7;

export const strapi = new Strapi({
  url: API_URL,
  store: { key: STRAPI_COOKIE, cookieOptions: { path: "/", expires: AUTH_EXPIRATION_IN_DAYS } },
});
