import { ReactComponent as BillingsOnIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=billings, State=on.svg";
import { ReactComponent as BillingsOffIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=billings, State=off.svg";
import { ReactComponent as DashboardOnIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=dashboard, State=on.svg";
import { ReactComponent as DashboardOffIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=dashboard, State=off.svg";
import { ReactComponent as AnalysisOnIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=analysis, State=on.svg";
import { ReactComponent as AnalysisOffIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=analysis, State=off.svg";
import { ReactComponent as CalendarOnIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=calendar, State=on.svg";
import { ReactComponent as CalendarOffIcon } from "@ui-kit/components/icons/svg/Dashboard/Type=calendar, State=off.svg";

export type Route = {
  translationKey: string;
  pathname: string;
  iconOn?: React.ReactNode;
  iconOff?: React.ReactNode;
};

type RouteKey =
  | "404"
  | "home"
  | "dashboard"
  | "signIn"
  | "signUp"
  | "signUpCompleted"
  | "resetPassword"
  | "forgotPassword"
  | "aboutUs"
  | "billings"
  | "expenses"
  | "myAnalyses"
  | "analysis"
  | "analyses"
  | "newAnalysis"
  | "analyst"
  | "analysts"
  | "newsfeed"
  | "qalis"
  | "myCalendar"
  | "profile"
  | "meeting-booking"
  | "editorialNotes"
  | "editorialNotesHeader"
  | "termsOfUse"
  | "legalNotice"
  | "privacyPolicy"
  | "academicNotes"
  | "academicNote";

export const routes: Record<RouteKey, Route> = {
  404: { translationKey: "nav.not-found", pathname: "/404" },
  home: {
    translationKey: "nav.home",
    pathname: "/",
  },
  dashboard: {
    translationKey: "nav.dashboard",
    pathname: "/dashboard",
    iconOn: <DashboardOnIcon className="icon text-primary-400 text-2xl" />,
    iconOff: <DashboardOffIcon className="icon text-grey-300 text-2xl" />,
  },
  signIn: {
    translationKey: "nav.signIn",
    pathname: "/sign-in",
  },
  signUp: {
    translationKey: "nav.signUp",
    pathname: "/sign-up",
  },
  signUpCompleted: {
    translationKey: "nav.signUpCompleted",
    pathname: "/sign-up-completed",
  },
  resetPassword: {
    translationKey: "nav.resetPassword",
    pathname: "/reset-password",
  },
  forgotPassword: {
    translationKey: "nav.forgotPassword",
    pathname: "/forgot-password",
  },
  aboutUs: {
    translationKey: "nav.aboutUs",
    pathname: "/about-us",
  },
  billings: {
    translationKey: "nav.billings",
    pathname: "/billings",
    iconOn: <BillingsOnIcon className="icon text-primary-400 text-2xl" />,
    iconOff: <BillingsOffIcon className="icon text-grey-300 text-2xl" />,
  },
  expenses: {
    translationKey: "nav.expenses",
    pathname: "/expenses",
    iconOn: <BillingsOnIcon className="icon text-primary-400 text-2xl" />,
    iconOff: <BillingsOffIcon className="icon text-grey-300 text-2xl" />,
  },
  myAnalyses: {
    translationKey: "nav.myAnalyses",
    pathname: "/my-analyses",
    iconOn: <AnalysisOnIcon className="icon text-primary-400 text-2xl" />,
    iconOff: <AnalysisOffIcon className="icon text-grey-300 text-2xl" />,
  },
  analyses: {
    translationKey: "nav.analysis",
    pathname: "/analyses",
  },
  analysis: {
    translationKey: "nav.analysis",
    pathname: "/analyses/[id]",
  },
  newAnalysis: {
    translationKey: "nav.newAnalyses",
    pathname: "/analyses/new",
  },
  analyst: {
    translationKey: "nav.analyst",
    pathname: "/analyst/[id]",
  },
  analysts: {
    translationKey: "nav.analysts",
    pathname: "/analysts",
  },
  newsfeed: {
    translationKey: "nav.newsfeed",
    pathname: "/newsfeed",
  },
  qalis: {
    translationKey: "nav.qalis",
    pathname: "/qalis",
  },
  myCalendar: {
    translationKey: "nav.calendar",
    pathname: "/my-calendar",
    iconOn: <CalendarOnIcon className="icon text-primary-400 text-2xl" />,
    iconOff: <CalendarOffIcon className="icon text-grey-300 text-2xl" />,
  },
  profile: {
    translationKey: "nav.profile",
    pathname: "/profile",
  },
  "meeting-booking": {
    translationKey: "nav.meetingBooking",
    pathname: "/meeting-booking",
  },
  editorialNotes: {
    translationKey: "nav.editorialNotes",
    pathname: "/editorial-notes",
  },
  editorialNotesHeader: {
    translationKey: "nav.editorialNotesHeader",
    pathname: "/editorial-notes",
  },
  termsOfUse: {
    translationKey: "footer.termsOfUse",
    pathname: "/terms-of-use",
  },
  legalNotice: {
    translationKey: "footer.legalNotice",
    pathname: "/legal-notice",
  },
  privacyPolicy: {
    translationKey: "footer.privacyPolicy",
    pathname: "/privacy-policy",
  },
  academicNotes: {
    translationKey: "nav.academicResearch",
    pathname: "/academic-notes",
  },
  academicNote: {
    translationKey: "nav.academicNote",
    pathname: "/academic-notes/[id]",
  },
};

export const headerNavigation: RouteKey[] = [
  "analysts",
  "analyses",
  "editorialNotesHeader",
  "newsfeed",
  "academicNotes",
  "qalis",
  "aboutUs",
];

export const footerNavigation: { sitePlan: RouteKey[]; partnerships: RouteKey[] } = {
  sitePlan: [
    "home",
    "analysts",
    "analyses",
    "editorialNotes",
    "newsfeed",
    "academicNotes",
    "aboutUs",
  ],
  partnerships: ["qalis"],
};

export const spacebarNavigationAnalyst: RouteKey[] = [
  "dashboard",
  "myAnalyses",
  "myCalendar",
  "billings",
];

export const spacebarNavigationInvestor: RouteKey[] = [
  "dashboard",
  "myAnalyses",
  "myCalendar",
  "expenses",
];
