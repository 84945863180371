import type {
  StrapiAuthenticationData,
  StrapiRegistrationData,
  StrapiUser,
  StrapiAuthenticationResponse,
  StrapiForgotPasswordData,
  StrapiResetPasswordData,
} from "strapi-sdk-js";
import { AUTH_EXPIRATION_IN_DAYS, strapi, STRAPI_COOKIE } from "@core/common/utils/strapi";
import Cookies from "js-cookie";

export const registerUser = async (
  registerData: StrapiRegistrationData
): Promise<StrapiAuthenticationResponse> => {
  return strapi.register(registerData);
};

export const loginUser = async (
  loginData: StrapiAuthenticationData
): Promise<StrapiAuthenticationResponse> => {
  return strapi.login(loginData);
};

export const logoutUser = () => {
  strapi.logout();
};

export const getUser = async (): Promise<StrapiUser> => {
  const user = await strapi.fetchUser();
  if (user === null) {
    throw new Error("User not found");
  }
  // Works only on the client-side
  const jwtToken = Cookies.get(STRAPI_COOKIE);
  if (jwtToken !== undefined) {
    Cookies.set(STRAPI_COOKIE, jwtToken, { path: "/", expires: AUTH_EXPIRATION_IN_DAYS });
  }
  return user as unknown as StrapiUser;
};

export const forgotPassword = async (
  forgotPasswordData: StrapiForgotPasswordData
): Promise<void> => {
  return strapi.forgotPassword(forgotPasswordData);
};

export const resetPassword = async (
  resetPasswordData: StrapiResetPasswordData
): Promise<StrapiAuthenticationResponse> => {
  return strapi.resetPassword(resetPasswordData);
};
