import { useHttpImmutable } from "@lib/http-client/useHttpImmutable";
import { getUser } from "@core/api/authentication/authentication";
import Cookies from "js-cookie";
import { STRAPI_COOKIE } from "@core/common/utils/strapi";

export const GET_USER_KEY = "/users/me";

export function useUser() {
  const { data, isValidating, error } = useHttpImmutable(GET_USER_KEY, () => {
    if (Cookies.get(STRAPI_COOKIE) != null) {
      return getUser();
    } else {
      return Promise.resolve(undefined);
    }
  });

  return {
    user: data,
    loading: isValidating && error === undefined,
  };
}
