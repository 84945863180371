import { useTranslation } from "@core/i18n/useTranslation";
import { TextField } from "@ui-kit/components/textField/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { superstructResolver } from "@hookform/resolvers/superstruct";
import {
  ContactFormSchema,
  ContactFormBodySchema,
  ConnectedContactFormBodySchema,
} from "@core/common/layouts/RootLayout/Footer/ContactFormSchema";
import { Button } from "@ui-kit/components/buttons/Button";
import { useNotification } from "@lib/notification/useNotification";
import { TextArea } from "@ui-kit/components/textArea/TextArea";
import { contactAltiplano } from "@core/api/common/contactAltiplano";
import { useUser } from "@core/common/hooks/useUser";

interface ContactFormProps {
  setShowContactModal: (state: boolean) => void;
}

export const ContactForm = ({ setShowContactModal }: ContactFormProps) => {
  const { t } = useTranslation("common");
  const { user } = useUser();
  const { notify } = useNotification();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormSchema>({
    resolver: superstructResolver(
      user === undefined ? ContactFormBodySchema : ConnectedContactFormBodySchema,
      {
        coerce: true,
        mask: true,
      }
    ),
    mode: "onTouched",
  });

  const onSubmit = async (formData: ContactFormSchema) => {
    try {
      await contactAltiplano(formData);
      notify(t("contactUs.success"), "success");
      setShowContactModal(false);
    } catch (error) {
      console.warn(error);
      notify(t("common:error"), "error");
    }
  };

  return (
    <>
      <section className={"flex flex-col-center px-4 xl:px-52 pb-8 font-light"}>
        <div className={"text-center"}>
          <h2 className={"heading2 xl:heading1 font-normal text-grey-400"}>
            {t("contactUs.contactUs")}
          </h2>
        </div>
        <form
          className={"flex flex-col gap-y-4 xl:gap-y-6 w-full pt-8 xl:pt-12 text-grey-300"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {user === undefined && (
            <>
              <div className="flex flex-col xl:flex-row gap-y-4 xl:gap-x-2">
                <TextField
                  label={t("contactUs.lastname")}
                  id={"lastname"}
                  error={errors.lastname}
                  errorText={t("form.error.required")}
                  register={register("lastname")}
                  type={"text"}
                  className="w-full"
                />
                <TextField
                  label={t("contactUs.firstname")}
                  id={"firstname"}
                  error={errors.firstname}
                  errorText={t("form.error.required")}
                  register={register("firstname")}
                  type={"text"}
                  className="w-full"
                />
              </div>
              <TextField
                label={t("contactUs.email")}
                id={"email"}
                error={errors.email}
                errorText={t("form.error.required")}
                register={register("email")}
              />
            </>
          )}
          <TextArea
            label={t("contactUs.message")}
            id={"message"}
            error={errors.message}
            errorText={t("form.error.required")}
            register={register("message")}
            inputClassName="xl:h-36"
          />
          <span className={"flex justify-center mt-2 xl:mt-4"}>
            <Button
              type="submit"
              disabled={isSubmitting}
              bgColor={"primary-400"}
              textColor={"white"}
            >
              {t("contactUs.button")}
            </Button>
          </span>
        </form>
      </section>
    </>
  );
};
