import type { Media } from "@core/api/common/types";

export const getImageUrl = (image: Media | null | undefined) => {
  if (image === null || image === undefined) return "/img/home/hero-background.jpg";
  return (
    image.formats?.large?.url ??
    image.formats?.medium?.url ??
    image.formats?.small?.url ??
    image.url
  );
};
