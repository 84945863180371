import { ReactComponent as LogoIcon } from "@ui-kit/components/icons/svg/Logo.svg";
import { ReactComponent as BurgerMenuIcon } from "@ui-kit/components/icons/svg/Burger Menu.svg";
import { ReactComponent as CrossBig } from "@ui-kit/components/icons/svg/CrossBig.svg";
import cx from "classnames";
import type { HeaderProps } from "@core/common/layouts/RootLayout/Header/Header";
import { Link } from "@ui-kit/components/link/Link";
import styles from "./HeaderMobile.module.css";
import headerStyles from "./Header.module.css";
import { headerNavigation, routes } from "@core/routes/routes";
import { useTranslation } from "@core/i18n/useTranslation";

export const HeaderMobile = ({
  className,
  isOpen,
  setOpen,
  userPart,
  authPart,
  activeRoute,
}: Omit<HeaderProps, "authLoading">) => {
  const toggleOpen = () => setOpen(!isOpen);
  const { t } = useTranslation("common");
  return (
    <nav
      className={cx(
        className,
        "z-50 fixed w-full h-16 overflow-hidden flex flex-col bg-white text-grey-400",
        styles.nav,
        {
          "overflow-scroll h-screen": isOpen,
        }
      )}
    >
      <div
        className={cx(
          styles.top,
          "bg-white relative w-full h-16 px-4 border-b border-grey-200 flex justify-between items-center"
        )}
      >
        <Link href={routes.home.pathname} aria-label={t("common:ariaLabels.home")}>
          <LogoIcon className={"w-14 h-14 text-primary-400"} />
        </Link>
        <button
          onClick={toggleOpen}
          aria-label={isOpen ? t("common:ariaLabels.closeMenu") : t("common:ariaLabels.openMenu")}
        >
          {isOpen ? (
            <CrossBig className={"icon text-2xl"} />
          ) : (
            <BurgerMenuIcon className={"icon text-2xl"} />
          )}
        </button>
      </div>
      {userPart !== null && (
        <div className={"px-4 py-5 border-b border-grey-200 bg-primary-200"}>{userPart}</div>
      )}
      <div>
        {headerNavigation.map((routeKey) => {
          const route = routes[routeKey];
          return (
            <div
              className={"w-full h-16 px-4 border-b border-grey-200 flex items-center"}
              key={routeKey}
            >
              <Link
                href={route.pathname}
                className={cx(activeRoute === route.pathname && headerStyles.active)}
              >
                {t(route.translationKey)}
              </Link>
            </div>
          );
        })}
      </div>
      <div className={"px-4 py-8 flex flex-col gap-4"}>{authPart}</div>
    </nav>
  );
};
