import { Email } from "@lib/validation/validation";
import { Describe, object, string, size } from "superstruct";

export type ContactFormSchema = {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
};

export const ContactFormBodySchema: Describe<ContactFormSchema> = object({
  firstname: size(string(), 1, Infinity),
  lastname: size(string(), 1, Infinity),
  email: Email,
  message: size(string(), 1, Infinity),
});

export type ConnectedContactFormSchema = {
  message: string;
};

export const ConnectedContactFormBodySchema: Describe<ConnectedContactFormSchema> = object({
  message: size(string(), 1, Infinity),
});
