import type { Fetcher, Key, SWRConfiguration, SWRResponse } from "swr";
import useSWRImmutable from "swr/immutable";

export function useHttpImmutable<Data = unknown, SWRKey extends Key = null>(
  key: SWRKey,
  fn: Fetcher<Data, SWRKey> | null,
  config?: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>> | undefined
): SWRResponse<Data, Error> {
  return useSWRImmutable(key, fn, config);
}
