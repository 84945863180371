import cx from "classnames";
import { HeaderDesktop } from "@core/common/layouts/RootLayout/Header/HeaderDesktop";
import { HeaderMobile } from "@core/common/layouts/RootLayout/Header/HeaderMobile";
import type { ComponentPropsWithoutRef } from "react";
import { Link } from "@ui-kit/components/link/Link";
import { Button } from "@ui-kit/components/buttons/Button";
// import { ReactComponent as NotificationIcon } from "@ui-kit/components/icons/svg/Notification.svg";
import { ReactComponent as LogoutIcon } from "@ui-kit/components/icons/svg/Logout.svg";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "@core/i18n/useTranslation";
import { getFullName } from "@core/common/utils/getFullName";
import { useUser } from "@core/common/hooks/useUser";
import { logoutUser } from "@core/api/authentication/authentication";
import { routes } from "@core/routes/routes";
import { isAnalyst } from "@core/api/authentication/utils";
import type { Dispatcher } from "@core/common/types/Dispatcher";
import styles from "./Header.module.css";
import { getImageUrl } from "@core/common/utils/getImageUrl";

export type HeaderProps = {
  isOpen: boolean;
  setOpen: Dispatcher<boolean>;
  userPart: JSX.Element | null;
  authPart: JSX.Element;
  activeRoute: string;
  authLoading: boolean;
} & ComponentPropsWithoutRef<"nav">;

export const Header = ({
  isOpen,
  setOpen,
}: Omit<HeaderProps, "userPart" | "authPart" | "activeRoute" | "authLoading">) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { user, loading: userLoading } = useUser();

  const logout = () => {
    logoutUser();
    router.reload();
  };

  let userPart = null;
  let authPart = (
    <>
      <Link href={routes.signIn.pathname}>
        <Button
          className={"w-full xl:w-auto mr-4"}
          textColor={"primary-400"}
          bgColor={"white"}
          outlined
        >
          {t("header.login")}
        </Button>
      </Link>
      <Link href={routes.signUp.pathname}>
        <Button className={"w-full xl:w-auto"} textColor={"white"} bgColor={"secondary-400"}>
          {t("header.createAccount")}
        </Button>
      </Link>
    </>
  );
  if (user != null) {
    userPart = (
      <>
        <div
          // className={"hidden xl:flex items-center px-5 h-full border-l border-r border-grey-200"}
          className={cx(
            styles.diviser,
            "hidden xl:flex items-center lg:px-3 px-5 h-full border-r border-grey-200"
          )}
          aria-label={t("common:ariaLabels.notifications")}
        >
          {/* <NotificationIcon className={"icon text-3xl "} /> */}
        </div>
        <Link
          className={
            "flex flex-row items-center hover:underline xl:bg-primary-200 xl:h-full xl:px-4"
          }
          href={routes.dashboard.pathname}
        >
          <div className={styles.imgContainer}>
            <Image
              lazyBoundary={"1000px"}
              className="rounded-full"
              layout="fill"
              src={getImageUrl(user.profilPictureUrl)}
              alt={t("common:header.profilePictureAlt")}
            />
          </div>
          <div className={"flex flex-col gap-1 pl-2"}>
            <p className="bodySmallText line-clamp-1">
              {getFullName(user.firstname, user.lastname)}
            </p>
            <p className="bodySmallText font-light">
              {isAnalyst(user) ? t("header.analystAccount") : t("header.investorAccount")}
            </p>
          </div>
        </Link>
      </>
    );

    authPart = (
      <>
        <Button
          className={"w-full xl:hidden"}
          textColor={"error-main"}
          bgColor={"white"}
          outlined
          onClick={logout}
        >
          {t("header.logout")}
        </Button>
        <button
          className={"hidden xl:flex items-center px-5 h-full border-l border-grey-200"}
          onClick={logout}
          aria-label={t("common:ariaLabels.logout")}
        >
          <LogoutIcon className={"icon text-3xl"} />
        </button>
      </>
    );
  }

  return (
    <>
      <HeaderMobile
        userPart={userPart}
        authPart={authPart}
        isOpen={isOpen}
        setOpen={setOpen}
        activeRoute={router.pathname}
        className={"xl:hidden ctaButtonText"}
      />
      <HeaderDesktop
        activeRoute={router.pathname}
        userPart={userPart}
        authPart={authPart}
        authLoading={userLoading}
        className={"hidden xl:flex ctaButtonText"}
      />
    </>
  );
};
